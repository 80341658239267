const PerformancesPage = ({ data }) => {
  const hero = data.performancesYaml.performancesHero;

  return (
    <Base>
      <PageMetadata page={data.metadata.siteMetadata.performancesPage} />

      <main id="main-content" tabIndex="-1" className="sans-serif">
        <Hero
          heading={hero.heading}
          image={hero.image.childImageSharp.fluid}
          alt={hero.alt}
          objPosition={hero.objPosition}
          quote={hero.quote}
          source={hero.source}
          link={hero.link}
        />

        <Bio paragraphs={data.performancesYaml.performanceBio} />

        <Listen examples={data.allListenYaml.edges} />

        <Schedule performances={data.allEventsPerformanceYaml.edges} />
      </main>
    </Base>
  );
};

/*
 *
 * Queries
 *
 */

export const query = graphql`
  query PerformancesQuery {
    metadata: site {
      siteMetadata {
        performancesPage {
          description
          title
          url
        }
      }
    }
    performancesYaml {
      performancesHero {
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        quote
        source
        link
      }
      performanceBio
    }
    allListenYaml {
      edges {
        node {
          title
          url
          category
        }
      }
    }
    allEventsPerformanceYaml {
      edges {
        node {
          dates
          project
          company
          link
          details {
            name
            value
          }
        }
      }
    }
  }
`;

/*
 *
 * Imports & Exports
 *
 */

import React from "react";
import { graphql } from "gatsby";

import Bio from "../sections/PerformancesBio";
import Listen from "../sections/PerformancesListen";
import Schedule from "../sections/PerformancesSchedule";

import Base from "../components/Base";
import Hero from "../components/Hero";
import PageMetadata from "../components/PageMetadata";

export default PerformancesPage;
