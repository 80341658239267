const Schedule = ({ performances }) => {
  let limit = 3

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // Create a test element to check for grid support
    const testEl = document.createElement(`div`)
    testEl.style.display = `grid`

    // If grid is supported, show more items initially on large screens
    if (testEl.style.display === `grid`) {
      if (window.matchMedia(`(min-width: 48em)`).matches) {
        limit = 4
      }
    }
  }

  return (
    <section className="bg-near-white pv5">
      <div className="container pv4 sm:mv3">
        <h2 className="section-heading">Schedule &amp; History</h2>

        <Limit items={performances} limit={limit} increment={limit}>
          {(visibleItems, limited, handleLoadMore) => (
            <Fragment>
              <Gigs gigs={visibleItems} />
              {limited && <LoadMore handleLoadMore={handleLoadMore} />}
            </Fragment>
          )}
        </Limit>
      </div>
    </section>
  )
}

/*
 *
 * Gigs
 *
 */

const Gigs = ({ gigs }) => (
  <ul className="schedule-grid">
    <TransitionGroup component={null}>
      {gigs.map(gig => (
        <Mount key={gig.node.dates} animateSpace={false} appear={false}>
          <Gig gig={gig.node} />
        </Mount>
      ))}
    </TransitionGroup>
  </ul>
)

/*
 *
 * Article
 *
 */

const Gig = ({ gig }) => (
  <li className="mt5 measure-medium" style={{ transformOrigin: `left` }}>
    <h3 className="lh-title f3 fw4">
      <Anchor href={gig.link} className="link-inline">
        {gig.company}
      </Anchor>
    </h3>

    <p className="f4 lh-copy">{gig.project}</p>

    <p className="mt3 lh-copy">{gig.dates}</p>

    <dl className="mt3 lh-copy">
      {gig.details.map((detail, i) => {
        return (
          <Fragment key={i}>
            <dt className="fl b">{detail.name}:&nbsp;</dt>
            <dd>{detail.value}</dd>
          </Fragment>
        )
      })}
    </dl>
  </li>
)

/*
 *
 * Load More
 *
 */

const LoadMore = ({ handleLoadMore }) => (
  <button onClick={handleLoadMore} className="link mt5">
    See more performances
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import Anchor from '../components/Anchor'
import Limit from '../components/Limit'
import Mount from '../components/Mount'

export default Schedule
