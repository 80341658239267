import React from "react";

import Anchor from "../components/Anchor";
import Icon from "../components/Icon";
import VideoGalleryAndLightbox from "../components/VideoGalleryAndLightbox";

import play from "../svg/play.svg";

function Example({ item, lightboxIndex, handleClick }) {
  return (
    <li className="relative flex items-center mt4 measure-wide lh-title tl">
      {item.category === `video` && (
        <button
          onClick={handleClick}
          value={lightboxIndex}
          className="link flex-none flex justify-center items-center mr3"
          style={{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: `.2em`,
            width: `2.75em`,
            height: `2.75em`
          }}
        >
          <Icon svg={play} style={{ height: `auto` }} />
          <span className="sr-only">Play ${item.title}</span>
        </button>
      )}

      {item.category === `link` && (
        <Anchor
          href={item.url}
          className="link flex-none flex justify-center items-center mr3"
          style={{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: `.2em`,
            width: `2.75em`,
            height: `2.75em`
          }}
        >
          <Icon svg={play} style={{ height: `auto` }} />
          <span className="sr-only">Play ${item.title}</span>
        </Anchor>
      )}

      <p className="sm:f4">{item.title}</p>
    </li>
  );
}

function Examples({ items, handleClick }) {
  let nextLightboxIndex = -1;

  return (
    <ul className="nt4 sm:pb3">
      {items.map(item => {
        if (item.node.category === `video`) nextLightboxIndex++;

        return (
          <Example
            key={item.node.url}
            item={item.node}
            lightboxIndex={
              item.node.category === `video` ? nextLightboxIndex : null
            }
            handleClick={handleClick}
          />
        );
      })}
    </ul>
  );
}

export default function Listen({ examples }) {
  const lightboxExamples = examples.filter(
    example => example.node.category === `video`
  );

  return (
    <section className="container mv4 pv5">
      <h2 className="section-heading mb5 sm:pt3">Listen</h2>

      <VideoGalleryAndLightbox
        galleryItems={examples}
        lightboxItems={lightboxExamples}
        renderGallery={(galleryItems, handleImageClick) => (
          <Examples items={galleryItems} handleClick={handleImageClick} />
        )}
      />
    </section>
  );
}
